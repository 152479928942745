body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

:root {
  --panel-color:rgba(3, 20, 49, 0.6);
  --secondary-color: #de9d0675
  /* rgba(3, 20, 49, 0.6) */

}

/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: 8px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* scroll bar end */

@font-face {
  font-family: 'HarlowSolid';
  src: url('./fonts/HarlowSolid.otf') format('opentype');
}

@font-face {
  font-family: 'Bauhaus93';
  src: url('./fonts/Bauhaus93.ttf') format('truetype');
}

@font-face {
  font-family: 'OfficialBook';
  src: url('./fonts/OfficialBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Bellerose';
  src: url('./fonts/Bellerose.ttf') format('truetype');
}

@font-face {
  font-family: 'Demode';
  src: url('./fonts/Demode.ttf') format('truetype');
}

@font-face {
  font-family: 'CandL';
  src: url('./fonts/Champagne&Limousines.ttf') format('truetype');
}

@font-face {
  font-family: 'CandL_Bold';
  src: url('./fonts/Champagne&LimousinesBold.ttf') format('truetype');
}

@font-face {
  font-family: 'TOMMY thin';
  src: url('./fonts/TOMMY_thin.otf') format('opentype');
}

@font-face {
  font-family: 'TOMMY regular';
  src: url('./fonts/TOMMY_regular.otf') format('opentype');
}

@font-face {
  font-family: 'TOMMY medium';
  src: url('./fonts/TOMMY_medium.otf') format('opentype');
}

@font-face {
  font-family: 'TOMMY bold';
  src: url('./fonts/TOMMY_bold.otf') format('opentype');
}
