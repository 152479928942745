.home-page-show-menu-container{
    width: 100%;
    height: auto;

    position: absolute;
    top: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.home-page-show-menu-button{
    width: 160px;
    height: 40px;

    border: 1px solid white;
    /* border-top: 0px; */

    background-color: rgba(3, 20, 49, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-family: 'Bauhaus93';
    font-size: 22px;
    color: white;

    cursor: pointer;
}

@media only screen and (orientation: landscape) and (max-height: 400px) {
    .home-page-show-menu-container{
        width: 100%;
        height: auto;
    
        position: absolute;
        top: 50px;
    
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2000;
    }
    
    .home-page-show-menu-button{
        width: 120px;
        height: 34px;
    
        border: 1px solid white;
        /* border-top: 0px; */
    
        background-color: rgba(3, 20, 49, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    
        font-family: 'Bauhaus93';
        font-size: 18px;
        color: white;
    }
}