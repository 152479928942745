.home-page {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);

  overflow: hidden;
}

.home-page-layover{
  position: absolute;
  width: 100%;
  height: 100%;

  /* background-color: rgba(0, 0, 0, 0.244); */

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 3px solid white;

  z-index: 5;
}


.home-page-interactive-menu-container{
  position: absolute;
  width: 252px;
  height: 0px;

  padding: 0px;
  padding-left: 5px;

  background-color: none;

  z-index: 10;

  overflow: hidden;

  display: flex;
  align-items: flex-start;
  justify-content: start;

  box-shadow:  rgb(0, 0, 0) 0px 0px 7px;

  -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

@media only screen and (orientation: landscape) and (max-height: 400px) {
  .home-page-interactive-menu-container{
    position: absolute;
    width: 252px;
    height: 0px;
  
    padding: 0px;
    padding-left: 5px;
  
    background-color: none;
  
    z-index: 10;
  
    overflow: hidden;
  
    display: flex;
    align-items: flex-start;
    justify-content: start;
  
    box-shadow:  rgb(0, 0, 0) 0px 0px 7px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

.home-page-interactive-menu-transition-helper{
  height: auto;
  width: auto;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin: 0;

  transition: ease 300ms;
  -webkit-transition: ease 300ms;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);

  will-change:contents;
}

