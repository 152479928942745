.invalid-form-warning-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130px;

    background-color: rgba(223, 3, 3, 0.5);

    color: white;
    font-size: 26px;
    font-family: 'OfficialBook';
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border: 5px solid white;
}