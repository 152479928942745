
.infinite-sliding-individual-panel{
    width: auto;
    height: 100%;

    position: absolute;
    top: 0px;
    /* left: 0px; */

    transition: linear 150000ms;
    transform: translateX(0px);

    padding-top: 3px;
    padding-bottom: 3px;

    background-color: black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    visibility: hidden;

}

.infinite-sliding-individual-panel-image{
    width: auto;
    height: 100%;

    transition: 2000ms;
    z-index: 101;
}

.panel-seperator{
    width: 3px;
    height: 100vh;
    z-index: 100;

    background-color: antiquewhite;
}

.infinite-sliding-panel-date{
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 17px;
    color: rgba(255, 255, 255, 1);
    font-family: 'TOMMY medium';

    padding-top: 15px;

    z-index: 102;
    text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;

     opacity: 1;
     transition: 0ms;

}

.infinite-sliding-panel-description{
    position: absolute;
    bottom: 0;
    width: 65%;
    max-width: 400px;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 17px;
    color: rgba(255, 255, 255, 1);
    font-family: 'TOMMY medium';

    padding-bottom: 15px;

    z-index: 102;
    text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;

     opacity: 1;
     transition: 0ms;
}

@media only screen and (orientation: landscape) and (max-height: 400px) {

    .infinite-sliding-panel-date{
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        font-family: 'TOMMY medium';
    
        padding-top: 15px;
    
        z-index: 102;
        text-shadow:
       -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
         1px 1px 0 #000;
    
         opacity: 1;
         transition: 0ms;
    
    }

    .infinite-sliding-panel-description{
        position: absolute;
        bottom: 0;
        width: 65%;
        max-width: 400px;
        height: auto;
    
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        font-family: 'TOMMY medium';
    
        padding-bottom: 15px;
    
        z-index: 102;
        text-shadow:
       -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
         1px 1px 0 #000;
    
         opacity: 1;
         transition: 0ms;
    }
}