.home-menu-panel-container{
  width: auto;
  height: auto;
  max-height: 332px;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;

  /* transition: 500ms; */
}

.hangout-with-andy-container{
  width: 240px;
  height: 90px;

  background-color: #de9d0675;
  border: 1px solid rgba(255, 255, 255, .75);

  font-family: 'Bauhaus93';
  font-size: 22px;
  color: 
  #ffffff;
  
  line-height: 36px;
  text-align: center;
  box-sizing: border-box;
}

.home-page-calendar-section-container{
  width: 240px;
  height: 175px;

  background-color: var(--panel-color);


  border: 1px solid rgba(255, 255, 255, .75);
}

.home-page-calendar-header{
  width: 100%;
  height: 45px;

  border-bottom: 1px solid rgba(255, 255, 255, .75);

  color: white;
  font-family: 'Bauhaus93';
  font-size: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
}

.home-page-calendar-container{
  width: 100%;
  height: 120px;
  
}

.view-more-button{
  width: 240px;
  height: 45px;

  background-color: var(--panel-color);
  border: 1px solid rgba(255, 255, 255, .75);

  font-family: 'Bauhaus93';
  font-size: 22px;
  color: white;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 

  transition: 300ms;
  
}

