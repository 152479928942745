  
  .down-arrow-container {
    position: relative;
    width: 29px;
    height: 14px;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(126, 61, 61); */
    

    padding-bottom: 30px;
  }
  
  .down-arrow-chevron {
    position: absolute;
    width: 17px;
    height: 1px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
  }
  
  .down-arrow-chevron:first-child {
    animation: move 3s linear 1s infinite;
  }
  
  .down-arrow-chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }
  
  .down-arrow-chevron:before,
  .down-arrow-chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
  }
  
  .down-arrow-chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }
  
  .down-arrow-chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }
  
  @keyframes move {
    25% {
      opacity: 1;
  
    }
    33% {
      opacity: 1;
      transform: translateY(5px);
    }
    67% {
      opacity: 1;
      transform: translateY(10px);
    }
    100% {
      opacity: 0;
      transform: translateY(15px);
    }
  }
  
  .text {
    display: block;
    margin-top: 50px;
    margin-left: -7px;
    font-family: 'OfficialBook';
    font-size: 8px;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: .25;
    animation: pulse 2s linear alternate infinite;
  }
  
  @keyframes pulse {
    to {
      opacity: 1;
    }
  }

  .scroll-text{
    color: white;
    font-size: 10px;
    font-family: 'TOMMY thin';

    margin-top: 10px;

  }