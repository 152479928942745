

/* PREV WEEK AND NEXT WEEK BUTTONS */
.icon {
  width: 40px;
  font-family: 'OfficialBook';
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";

  /* background-color: #1a8fff; */
}

/* VARIABLES */

:root {
  --main-color: #000000; 
  --text-color: rgb(255, 255, 255); /* ALL TEXT COLOR EXCEPT WEEK DAYS */
  --text-color-light: rgb(255, 255, 255); /* WEEKDAYS */
  --border-color: none; /* BORDERS */
  --bg-color: #eca90d; /* BG? */
  --neutral-color: rgba(255,255,255,.0); /* ACTUAL BACKGROUND COLOR */
}

/* GENERAL */


/* GRID */

.row {
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
  width: 100%;
  visibility: visible;

}

.row-weekdays {
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0px;
  width: 100%;

}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */


/* CONTAINER */
.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: none;

  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
  visibility: visible;
  
  /* border: 1px solid var(--border-color); */
}

/* @media only screen and (orientation: landscape) and (max-height: 400px) {
  .calendar {
    display: block;
    position: relative;
    width: 100%;
    background: none;
  
    cursor: pointer;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    
    visibility: hidden;
  }

   .row {
    margin: 0;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3px;
    width: 100%;
    visibility: hidden;

  } 
} */

/* TOP AND BOTTOM PARTS */
.calendar .header {
  /* text-transform: uppercase; */
  font-size: 18px;

  font-family: 'TOMMY medium';
  color: rgb(255, 255, 255);
  height: 20px;
  line-height: 40px;
  
}

/* NEXT AND PREVIOUS BUTTONS */
.calendar .header .icon {
  cursor: pointer;
  transition: 200ms ease-out;
  
}

/* NEXT AND PREVIOUS BUTTONS */
.calendar .header .icon:hover {
  /*transform: scale(1.75);*/
  transition: 0.25s ease-out;
  /* color: red; */
  /* border:2px solid #722a2a; */
  
}

/* NEXT BUTTON MARGIN */
.calendar .header .icon:last-of-type {
  margin-right: 35px;
  height: 30px;
  margin-top: 15px;  
  
  
}

/* PREVIOUS BUTTON MARGIN */
.calendar .header .icon:first-of-type {
  margin-left: 35px;

  height: 30px;
  margin-top: 15px;  
}

/* 
.calendar .header .icon {
  
  color: var(--bg-color);
} */



/* WEEK DAY WORDS */
.calendar .days {
  text-transform: uppercase;

  color: white;
  font-size: 14px;
  font-family: 'TOMMY regular';

  width: 100%;
  
}

/* ACTUAL CELLS TO CLICK */
.calendar .body .cell {
  position: relative;
  /* border-right: 10px solid var(--border-color); */
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  font-size: 15px;

  /* border-radius: 50%; */
  border: 1px solid white;

  background-color: rgba(255, 255, 255, .35);

  height: 32px;
  

}

/* .calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
} */

.calendar .body .selected {
  /* border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1; */
}
.calendar .body .today {
  /* border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
  border-image-slice: 1; */
}

/* ACTUAL CELL TEXT */
.calendar .body .cell .number {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-family: 'TOMMY regular';
  color: white;

  text-align: center;

  background-color: none;

  height: 100%;
  transition: 500ms;
  
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
  
}

/* .calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: .9;
  transition: 0.5s ease-in;
}
.calendar .body .cell.today .bg {
  color: #ff1a79;
  opacity: 1;
} */

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7.8);
  width: calc(100% / 7.8);
  
}

.calendar-arrows-container{
  width: 100%;
  height: 50px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.calendar-arrows-single-arrow{
  width: 50%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 30px;
  color: white;
}

.arrow-right {
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;

  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow-left {
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;

  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow-left-line{
  width: 15px;
  height: 1px;
  background-color: white;
  margin-left: -7px;
}

.arrow-right-line{
  width: 15px;
  height: 1px;
  background-color: white;
  margin-right: -7px;
}