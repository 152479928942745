.hang-out-form-panel-container{
    width: auto;
    height: auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0;
    margin: 0;
}

.hang-out-form-back-button{
    width: 240px;
    height: 45px;

    background-color: #de9d0675;
    border: 1px solid rgba(255, 255, 255, .75);

    color: white;
    font-family: 'Bauhaus93';
    font-size: 22px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    transition: 750ms ease;

    cursor: pointer;
}

.hang-out-form-lower-box{
    width: 240px;
    height: 270px;

    border: 1px solid rgba(255, 255, 255, .75);

    display: flex;
    flex-direction: column;
    
    /* overflow: auto; */
}

.hang-out-form-slider{
    height: 262px;
    width: 100%;

    /* overflow: auto; */
    background-color: rgba(255, 255, 255, 0);
    background-color: var(--panel-color);

    overflow: auto;
    transition: 250ms ease;
    transform: translateY(0px);
}


.hang-out-form-contents-container{
    height: auto;
    width: 100%;

    /* border-top: 1px solid white; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    gap: 35px;
    /* overflow: auto; */
    /* background-color: rgba(250, 235, 215, 0.375); */
    padding-top: 12px;
    padding-bottom: 12px;

    transition: 1500ms ease;
    transform: translateY(0px);
}

.hang-out-form-selected-date-container{
    width: 100%;
    height: 45px;
    background-color: var(--panel-color);

    /* padding-top: 5px; */
    /* padding-bottom: 5px; */

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 18px;
    font-family: 'TOMMY bold';

    border-bottom: 1px solid rgba(255, 255, 255, .75);
}

.hang-out-form-question-answer-container{
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    

}

.hang-out-form-question-container{
    width: 100%;
    height: auto;

    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: 'TOMMY medium';

    line-height: 18px;
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;

}

.hang-out-form-answer-container{
    width: 100%;
    height: auto;

    color: white;
    font-size: 14px;
    font-family: 'TOMMY regular';

    line-height: 18px;
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 5px;

    background-color: rgba(255, 255, 255, 0);
    border: 0px;
    overflow: hidden;

    resize: none;
}

.hang-out-form-phone-number-input{
    width: 100%;
    height: auto;

    color: white;
    font-size: 14px;
    font-family: 'TOMMY regular';
    
    line-height: 18px;
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 5px;

    letter-spacing: 0px;

    background-color: rgba(255, 255, 255, 0);
    border: 0px;
    overflow: hidden;

    resize: none;
}

.hang-out-form-answer-container:focus{
    width: 100%;
    height: auto;

    color: white;

    font-size: 14px;
    font-family: 'TOMMY regular';

    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 5px;

    background-color: rgba(255, 255, 255, 0);
    outline: 1px solid white;
    word-wrap: break-word;
    
    overflow: hidden;
    resize: none;

}

.down-arrow{
    position: absolute;
    bottom: 0px;
    right: 5px;

    width: auto;
    height: auto;

    /* background-color: rgb(90, 32, 32); */
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    transition: 5000ms;
    opacity: 1;
}

.hang-out-form-submit-button{

    width: 100%;
    height: 40px;

    background-color: #de9d0675;
    border: 0px;
    border-top: 1px solid rgba(255, 255, 255, .75);
    border-bottom: 1px solid rgba(255, 255, 255, .75);

    color: white;
    font-family: 'Bauhaus93';
    font-size: 22px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    transition: 200ms ease;
    transform: translateY(0px);

    cursor: pointer;
}
