.hahaha{
    position: absolute;
    top: 0;
    left: 0;
}

.cough{
    position: absolute;
    top: 0;
    right: 0;
}

.infinite-slider-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgb(0, 0, 0);

    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.infinite-slider-panels-container{
    width: auto;
    height: 100%;

    display: flex;
    align-items: center;
    flex-direction: row;

    background-color: rgb(19, 18, 18);
    
    transition: ease 11000s;
}

.end-of-photos-text{
    color: white;
    font-size: 20px;
    font-family: 'TOMMY regular';

    text-align: center;
    max-width: 70%;
    margin-top: 50px;
}