.confirmed-hang-out-container{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}

.confirmed-hang-out-text{
    width: auto;
    height: auto;

    color: white;
    font-size: 20px;
    font-family: 'TOMMY bold';
    text-align: center;
}

.confirmed-hang-out-fancy-text{
    width: auto;
    height: auto;

    color: rgb(32, 20, 20);
    font-size: 26px;
    font-family: 'HarlowSolid';
}

.confirmed-hang-out-picture{
    width: auto;
    max-width: 110px;

    height: auto;
    max-height: 200px;

    opacity: 1;

    /* transition: 1000ms;
    transform: rotate(-1080deg) scale(0.1); */
}